import React, { useState, useEffect } from 'react';
import {
  addModuleInDatabaseToTenant,
  activateChatModuleForAllKitasOfTenant,
} from 'library/api/modules';
import {
  addAllParentsToPortfolioGroups,
  addAllAdminEmployeeGroupMembersToPortfolioGroups,
  removeLinkitisiUsersFromKidPortfolios,
  addQRCodeRole,
  transferKidGroupToKidMainGroup,
  giveUsersInPortfolioGroupsPermissionsToUploadFile,
  migrateAbsences,
  kidExitNotification,
  deleteDuplicateAuthorizedToPickUpChild,
  fixBirthays,
} from 'library/api/kids';

import {
  downloadAllGroupCalendarExportsFromKita,
  migrateCalendarEntries,
} from 'library/api/calendar';
import { migrateFolders } from 'library/api/files';
import { deleteAllDuplicatePosts } from 'library/api/posts';
import { deleteAllDuplicateFolders } from 'library/api/folder';
import {
  fixBrokenKitaRolesPermissions,
  fixBrokenTraegerDatabaseEntries,
  fixBrokenSafe2ConnectDatabaseEntries,
} from 'library/api/kita';
import { deleteAllDuplicateFoodOrders } from 'library/api/foodOrder';
import Button from 'library/common/commonComponents/Buttons/Button';
import Input from 'library/common/commonComponents/Inputs/Input';
import DatePicker from 'library/common/commonComponents/DatePicker';
import { addFavoritesToGroupsAWO, removeNotificationGroupsAWO, sendReleaseNotesMail } from 'library/api/notifications';
import Loader from 'library/common/commonComponents/Loader';
import { useTranslation } from 'react-i18next';
import {
  resetPasswordForAllUsersTenant,
  resetPasswordForAllUsersTenantThatWereNotLoggedIn,
  allChristusErloeserConsentsToExcel,
  uploadMultipleUserLogos,
  downloadUserLogosAsZipFiles,
  uploadMultipleGroupLogos,
  downloadGroupLogosAsZipFiles,
  getIp,
  deleteExpiredOAuthTokens,
} from 'library/api/user';
import { recalculateEmployeeWorkingDays } from 'library/api/employee';
import { downloadFile } from 'library/utilities/files';

import { addAllAwowwUsersToAllWelcomeGroups } from 'library/common/commonActions/groupActions';


import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Select from 'library/common/commonComponents/Inputs/Select';

import PostMigration from './PostMigration';
import KidAutoCheckout from './KidAutoCheckOut';
import NewGroupPermissionPopup from './NewGroupPermissionPopup';
import UpdateGroupPermissionPopup from './UpdateGroupPermissionPopup';
import { isAwoWW } from 'library/api/tenantConfig';

export default function GlobalTools({ user, activeKita }) {
  const [moduleInformation, setModuleInformation] = useState({
    moduleKey: '',
    moduleId: 0,
    moduleDescription: '',
    moduleName: '',
  });

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [releaseNotesMail, setReleaseNotesMail] = useState('natascha.knorpp@gfz-zh.ch');
  const [sentEmails, setSentEmails] = useState([]);
  const [sentEmailsNotLoggedIn, setSentEmailsNotLoggedIn] = useState([]);
  const [calendarMigrationKitaId, setCalendarMigrationKitaId] = useState('');
  const [folderMigrationKitaId, setFolderMigrationKitaId] = useState('');
  const [absencesMigrationKitaId, setAbsencesMigrationKitaId] = useState('');
  const [ipAdress, setIpAdress] = useState('');
  const [recalculateWorkingDaysDate, setRecalculateWorkingDaysDate] = useState(null);

  const uploadMultipleUserLogosFunction = e => {
    const files = e.target.files;
    const data = new FormData();
    files.forEach(file => {
      data.append('files', file);
    });

    setIsLoading(true);

    uploadMultipleUserLogos(data)
      .then(() => {
        showBottomNotification(t('BottomNotifications.Success'));
      })
      .catch(() => {
        showBottomNotification(t('BottomNotifications.Something went wrong'), {
          isFail: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getIp().then(data => {
      setIpAdress(data.data);
      console.log(data.data);
    });
  }, []);

  const uploadMultipleGroupLogosFunction = e => {
    const files = e.target.files;
    const data = new FormData();
    files.forEach(file => {
      data.append('files', file);
    });

    setIsLoading(true);

    uploadMultipleGroupLogos(data)
      .then(() => {
        showBottomNotification(t('BottomNotifications.Success'));
      })
      .catch(() => {
        showBottomNotification(t('BottomNotifications.Something went wrong'), {
          isFail: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const moduleOptions = [
    { value: null, label: '' },
    { value: 1, label: 'files' },
    { value: 2, label: 'calendar' },
    { value: 3, label: 'gallery' },
    { value: 4, label: 'survey' },
    { value: 5, label: 'tasks' },
    { value: 6, label: 'translations' },
    { value: 7, label: 'absence' },
    { value: 8, label: 'image_download' },
    { value: 9, label: 'parent_survey' },
    { value: 10, label: 'suggestion_box' },
    { value: 11, label: 'signature' },
    { value: 12, label: 'tax_consultant' },
    { value: 13, label: 'portfolio' },
    { value: 14, label: 'bazaar' },
    { value: 15, label: 'checkinout' },
    { value: 16, label: 'food_order' },
    { value: 17, label: 'wiki' },
    { value: 18, label: 'corona_test' },
    { value: 19, label: 'chat' },
    { value: 20, label: 'whiteboard' },
    { value: 21, label: 'employee_portfolio' },
    { value: 22, label: 'employee_checkinout' },
    { value: 25, label: 'working_hours' },
    { value: 26, label: 'kitalino_interface' },
    { value: 27, label: 'table_templates' },
  ];

  const [module, setModule] = useState(moduleOptions[0]);
  const [isNewGroupPermissionPopupOpened, setIsNewGroupPermissionPopupOpened] = useState(false);
  const [
    isGroupPermissionTranslationPopupOpened,
    setIsGroupPermissionTranslationPopupOpened,
  ] = useState(false);

  function handleStateChange(newValue, property) {
    setModuleInformation(prev => {
      return {
        ...prev,
        [property]: newValue,
      };
    });
  }

  return !isLoading ? (
    <div>
      {(user.email.includes('@stramplerbande') || user.email.includes('@link-it-isi')) && (
        <div>
          <div>
            <div>
              <h3>{`Deine IP-Adresse ist: ${ipAdress}`}</h3>
              <br />
            </div>
            <div>
              <h3>{t('GlobalTools.Child exit notif')}</h3>
              <br />
            </div>
            <br />
            <Button
              onClick={() => {
                setIsLoading(true);
                kidExitNotification()
                  .then(data => {
                    store.dispatch(showBottomNotification(t('BottomNotifications.Success')));
                  })
                  .catch(data => {
                    store.dispatch(
                      showBottomNotification(t('BottomNotifications.Something went wrong'), {
                        isFail: true,
                      }),
                    );
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });
              }}
            >
              {t('GlobalTools.Child exit notif')}
            </Button>
            <br />
            <br />
            <div>
              <h3>{t('GlobalTools.Delete all duplicate folders for tenant')}</h3>
              <br />
            </div>
            <br />
            <Button
              onClick={() => {
                setIsLoading(true);
                deleteAllDuplicateFolders()
                  .then(data => {
                    store.dispatch(showBottomNotification(t('BottomNotifications.Success')));
                  })
                  .catch(data => {
                    store.dispatch(
                      showBottomNotification(t('BottomNotifications.Something went wrong'), {
                        isFail: true,
                      }),
                    );
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });
              }}
            >
              {t('GlobalTools.Delete')}
            </Button>
            <br />
            <br />
            <br />
            <hr />
            <div>
              <h3>{t('GlobalTools.Delete all duplicate posts for tenant')}</h3>
              <br />
            </div>
            <br />
            <Button
              onClick={() => {
                setIsLoading(true);
                deleteAllDuplicatePosts()
                  .then(data => {
                    store.dispatch(showBottomNotification(t('BottomNotifications.Success')));
                  })
                  .catch(data => {
                    store.dispatch(
                      showBottomNotification(t('BottomNotifications.Something went wrong'), {
                        isFail: true,
                      }),
                    );
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });
              }}
            >
              {t('GlobalTools.Delete')}
            </Button>
            <br />
            <br />
            <br />
            <hr />
            <div>
              <h3>{t('GlobalTools.Absences migration')}</h3>
              <br />
            </div>
            <br />
            <Input
              type='text'
              value={absencesMigrationKitaId}
              onChange={e => setAbsencesMigrationKitaId(e.target.value)}
              placeholder={'GlobalTools.Id of source kita'}
            />
            <br />
            <Button
              onClick={() => {
                setIsLoading(true);
                migrateAbsences(absencesMigrationKitaId)
                  .then(data => {
                    store.dispatch(showBottomNotification(t('BottomNotifications.Success')));
                  })
                  .catch(data => {
                    store.dispatch(
                      showBottomNotification(t('BottomNotifications.Something went wrong'), {
                        isFail: true,
                      }),
                    );
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });
              }}
            >
              {t('GlobalTools.Migrate')}
            </Button>
            <br />
            <br />
            <br />
            <hr />
            <div>
              <h3>{t('GlobalTools.Folder migration')}</h3>
              <br />
            </div>
            <br />
            <Input
              type='text'
              value={folderMigrationKitaId}
              onChange={e => setFolderMigrationKitaId(e.target.value)}
              placeholder={'GlobalTools.Id of source kita'}
            />
            <br />
            <Button
              onClick={() => {
                setIsLoading(true);
                migrateFolders(folderMigrationKitaId)
                  .then(data => {
                    store.dispatch(showBottomNotification(t('BottomNotifications.Success')));
                  })
                  .catch(data => {
                    store.dispatch(
                      showBottomNotification(t('BottomNotifications.Something went wrong'), {
                        isFail: true,
                      }),
                    );
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });
              }}
            >
              {t('GlobalTools.Migrate')}
            </Button>
            <br />
            <br />
            <br />
            <hr />
            <div>
              <h3>{t('GlobalTools.Calendar migration')}</h3>
              <br />
            </div>
            <br />
            <Input
              type='text'
              value={calendarMigrationKitaId}
              onChange={e => setCalendarMigrationKitaId(e.target.value)}
              placeholder={'GlobalTools.Id of source kita'}
            />
            <br />
            <Button
              onClick={() => {
                setIsLoading(true);
                migrateCalendarEntries(calendarMigrationKitaId)
                  .then(data => {
                    store.dispatch(showBottomNotification(t('BottomNotifications.Success')));
                  })
                  .catch(data => {
                    store.dispatch(
                      showBottomNotification(t('BottomNotifications.Something went wrong'), {
                        isFail: true,
                      }),
                    );
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });
              }}
            >
              {t('GlobalTools.Migrate')}
            </Button>
            <br />
            <br />
            <br />
            <hr />
            <div>
              <h3>{t('GlobalTools.Download all calendar exports for kita')}</h3>
              <br />
            </div>
            <br />
            <br />
            <Button
              onClick={() => {
                downloadAllGroupCalendarExportsFromKita();
              }}
            >
              {t('GlobalTools.Download')}
            </Button>
            <br />
            <br />
            <br />
            <hr />
            <div>
              <h3>{t('GlobalTools.Group logos')}</h3>
              <br />
              {t('GlobalTools.Upload')}&nbsp;
              <input type='file' onChange={uploadMultipleGroupLogosFunction} multiple={true} />
            </div>
            <br />
            <br />
            <Button
              onClick={() => {
                downloadGroupLogosAsZipFiles();
              }}
            >
              {t('GlobalTools.Download')}
            </Button>
            <br />
            <br />
            <br />
            <hr />
            <h3>{t('GlobalTools.User logos')}</h3>
            <br />
            <div>
              {t('GlobalTools.Upload')}&nbsp;
              <input type='file' onChange={uploadMultipleUserLogosFunction} multiple={true} />
            </div>
            <br />
            <br />
            <Button
              onClick={() => {
                downloadUserLogosAsZipFiles();
              }}
            >
              {t('GlobalTools.Download')}
            </Button>
            <br />
            <br />
            <hr />
            <br />
            <div>
              <Button
                onClick={() => {
                  setIsLoading(true);
                  removeLinkitisiUsersFromKidPortfolios()
                    .then(data => {
                      setIsLoading(false);
                    })
                    .finally(data => {
                      setIsLoading(false);
                    });
                }}
              >
                {t('GlobalTools.Remove Stramplerbande from portfolio groups')}
              </Button>
            </div>
            <br />
            <hr />
            <h3>Migration KinderGruppe zu Hauptgruppe</h3>
            <br />
            <Button
              onClick={() => {
                setIsLoading(true);
                transferKidGroupToKidMainGroup()
                  .then(res => { })
                  .finally(() => {
                    setIsLoading(false);
                  });
              }}
            >
              Migration KinderGruppe zu Hauptgruppe
            </Button>
          </div>
          <br />
          <KidAutoCheckout />
          <br />
          <br />
          <div>
            <h3> Download Christus Erlöser Consent</h3>
            <br />
            <Button
              onClick={() => {
                setIsLoading(true);
                allChristusErloeserConsentsToExcel()
                  .then(res => {
                    downloadFile(res, 'ChristusErloeserConsent.xlsx', 'application/vnd.ms-excel');
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });
              }}
            >
              Download Christus Erlöser Consent
            </Button>
          </div>
          <br />
          <hr />
          <br />
          <div>
            <h3> Release Notes Mail senden</h3>
            <br />
            <div>
              Mail:
              <Input
                type='text'
                value={releaseNotesMail}
                onChange={e => setReleaseNotesMail(e.target.value)}
              />
            </div>
            <Button
              onClick={() => {
                setIsLoading(true);
                sendReleaseNotesMail(releaseNotesMail)
                  .then(res => { })
                  .finally(() => {
                    setIsLoading(false);
                  });
              }}
            >
              Senden
            </Button>
          </div>
          <br />
          <hr />
          <br />
          <div>
            <h3>Doppelte Bestellungen löschen</h3>
            <br />
            <Button
              onClick={() => {
                setIsLoading(true);
                deleteAllDuplicateFoodOrders().finally(() => {
                  setIsLoading(false);
                });
              }}
            >
              Doppelte Bestellungen löschen
            </Button>
          </div>
          <br />
          <div>
            <PostMigration />
            <br />
            <br />
            <br />
            <hr />
            <br />
          </div>
          <div>
            <Button
              onClick={() => {
                setIsLoading(true);
                fixBrokenTraegerDatabaseEntries()
                  .then(data => { })
                  .finally(data => {
                    setIsLoading(false);
                  });
              }}
            >
              Fix für fehlende Träger Datenbankeinträge
            </Button>
          </div>
          <br />
          <br />
          <hr />
          <br />
          <div>
            <Button
              onClick={() => {
                setIsLoading(true);
                fixBrokenSafe2ConnectDatabaseEntries()
                  .then(data => { })
                  .finally(data => {
                    setIsLoading(false);
                  });
              }}
            >
              Fix für fehlende Safe2Connect Datenbankeinträge
            </Button>
          </div>
          <br />
          <br />
          <hr />
          <br />
          <div>
            <Button
              onClick={() => {
                setIsLoading(true);
                fixBrokenKitaRolesPermissions()
                  .then(data => { })
                  .finally(data => {
                    setIsLoading(false);
                  });
              }}
            >
              Fix für kaputte Kita Rollen Berechtigungen
            </Button>
          </div>
          <br />
          <br />
          <hr />
          <br />
          <div>
            <Button
              onClick={() => {
                setIsLoading(true);
                resetPasswordForAllUsersTenantThatWereNotLoggedIn()
                  .then(data => {
                    setSentEmailsNotLoggedIn(data.data);
                  })
                  .finally(data => {
                    setIsLoading(false);
                  });
              }}
            >
              E-Mail versenden zum Passwort zurücksetzen für User die noch nicht eingeloggt waren
            </Button>
          </div>
          <br />
          {sentEmailsNotLoggedIn.map(email => {
            return <div>{email}</div>;
          })}
          <br />
          <br />
          <hr />
          <br />
          <div>
            <Button
              onClick={() => {
                setIsLoading(true);
                resetPasswordForAllUsersTenant()
                  .then(data => {
                    setSentEmails(data.data);
                  })
                  .finally(data => {
                    setIsLoading(false);
                  });
              }}
            >
              E-Mail Passwort zurücksetzen versenden GFZ
            </Button>
          </div>
          <br />
          <hr />
          <br />
          {sentEmails.map(email => {
            return <div>{email}</div>;
          })}
          <br />
          <br />
          <Select
            options={moduleOptions}
            onSelect={e => {
              setModule(e);
              handleStateChange(e.value, 'moduleId');
              handleStateChange(e.label, 'moduleKey');
            }}
            selected={module}
          />
          <div>
            Beschreibung:
            <Input
              type='text'
              value={moduleInformation.moduleDescription}
              onChange={e => handleStateChange(e.target.value, 'moduleDescription')}
            />
          </div>
          <div>
            Name:
            <Input
              type='text'
              value={moduleInformation.moduleName}
              onChange={e => handleStateChange(e.target.value, 'moduleName')}
            />
          </div>
          <br />
          <Button
            onClick={() => {
              if (
                moduleInformation.moduleKey === '' ||
                moduleInformation.moduleId === 0 ||
                moduleInformation.moduleDescription === '' ||
                moduleInformation.moduleName === ''
              ) {
                return;
              }
              setIsLoading(true);
              addModuleInDatabaseToTenant(moduleInformation)
                .then(() => {
                  setIsLoading(false);

                  store.dispatch(
                    showBottomNotification('Erfolg', {
                      isFail: false,
                    }),
                  );
                })
                .catch(() => {
                  setIsLoading(false);
                  store.dispatch(
                    showBottomNotification('Fehler', {
                      isFail: true,
                    }),
                  );
                });
            }}
          >
            Modul hinzufügen
          </Button>
          <br />
          <br />
          <hr />
          <br />
          <Button
            onClick={() => {
              setIsLoading(true);
              activateChatModuleForAllKitasOfTenant()
                .then(() => {
                  setIsLoading(false);
                  store.dispatch(
                    showBottomNotification('Erfolg', {
                      isFail: false,
                    }),
                  );
                })
                .catch(() => {
                  setIsLoading(false);
                  store.dispatch(
                    showBottomNotification('Fehler', {
                      isFail: true,
                    }),
                  );
                });
            }}
          >
            Chat Modul bei allen Kitas des Tenants aktivieren
          </Button>
          <br />
          <br />
          <hr />
          <br />
          {/*<InstitutMigration />*/}
        </div>
      )}
      <div>
        <br />
        <div>
          <Button
            onClick={() => {
              setIsLoading(true);
              addAllParentsToPortfolioGroups()
                .then(data => {
                  setIsLoading(false);
                })
                .finally(data => {
                  setIsLoading(false);
                });
            }}
          >
            Alle Eltern zu den Portfoliogruppen der Kita hinzufügen
          </Button>
        </div>
        <br />
        <hr />
        <br />
        <div>
          <Button
            onClick={() => {
              setIsLoading(true);
              giveUsersInPortfolioGroupsPermissionsToUploadFile()
                .then(data => {
                  setIsLoading(false);
                })
                .finally(data => {
                  setIsLoading(false);
                });
            }}
          >
            {t('GlobalTools.Give users in portfolio groups upload permissions')}
          </Button>
        </div>
        <br />
        <br />
        <div>
          <Button
            onClick={() => {
              setIsLoading(true);
              addAllAdminEmployeeGroupMembersToPortfolioGroups()
                .then(data => {
                  setIsLoading(false);
                })
                .finally(data => {
                  setIsLoading(false);
                });
            }}
          >
            {t('GlobalTools.Add Admins and Employees to Portfolio Groups')}
          </Button>
        </div>
        <br />
        <hr />
      </div>
      <div>
        <br />
        <Button
          onClick={() =>
            addQRCodeRole().then(data => {
              store.dispatch(
                showBottomNotification('Erfolg', {
                  isFail: false,
                }),
              );
            })
          }
        >
          {t('GlobalTools.Add QR Code Role')}
        </Button>
        <br />
        <br />
        <hr />
      </div>
      <div>
        <br />
        <Button onClick={() => setIsNewGroupPermissionPopupOpened(true)}>
          {t('GlobalTools.Add New Group Permission')}
        </Button>
        <br />
        <br />
        <hr />
      </div>
      <div>
        <br />
        <Button onClick={() => setIsGroupPermissionTranslationPopupOpened(true)}>
          {t('GlobalTools.UpdateGroupPermissionTranslation')}
        </Button>
      </div>
      <NewGroupPermissionPopup
        isOpen={isNewGroupPermissionPopupOpened}
        setOpen={setIsNewGroupPermissionPopupOpened}
      />
      <UpdateGroupPermissionPopup
        isOpen={isGroupPermissionTranslationPopupOpened}
        setOpen={setIsGroupPermissionTranslationPopupOpened}
      />
      <br />
      <hr />
      <br />

      <h3>{t('GlobalTools.Recalculate Working Days')}</h3>
      <br />
      <DatePicker
        selected={recalculateWorkingDaysDate}
        onChange={date => {
          setRecalculateWorkingDaysDate(date);
        }}
        langCode={user.currentWatsonLang === 'de' ? 'Deutsch' : 'en'}
        maxDate={new Date().setDate(new Date().getDate() - 1)}
      />
      <br />
      <Button
        onClick={() => {
          if (!recalculateWorkingDaysDate) {
            return;
          }
          setIsLoading(true);

          const formatDate = date => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure two digits
            const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits

            return `${year}-${month}-${day}`;
          };

          const date = formatDate(recalculateWorkingDaysDate);
          recalculateEmployeeWorkingDays({ date })
            .then(data => {
              store.dispatch(showBottomNotification(t('BottomNotifications.Success')));
            })
            .catch(data => {
              store.dispatch(
                showBottomNotification(t('BottomNotifications.Something went wrong'), {
                  isFail: true,
                }),
              );
            })
            .finally(() => {
              setIsLoading(false);
            });
        }}
      >
        {t('GlobalTools.Recalculate Working Days')}
      </Button>
      <br />
      <br />
      <hr />
      <br />
      {isAwoWW() && (<><Button
        onClick={() => {
          removeNotificationGroupsAWO().then((res) => {
            store.dispatch(showBottomNotification(t('BottomNotifications.Success')));
          });
        }}>
        {t('GlobalTools.RemoveGroupsFromNotificationListForAWO')}
      </Button><br /><br /><hr /><br /></>)}
      {isAwoWW() && (<><Button
        onClick={() => {
          addFavoritesToGroupsAWO().then((res) => {
            store.dispatch(showBottomNotification(t('BottomNotifications.Success')));
          });
        }}>
        {t('GlobalTools.AddFavoritesToNotificationListForAWO')}
      </Button><br /><br /><hr /></>)}
      <Button
        onClick={() => {
          fixBirthays().then((res) => {
            store.dispatch(showBottomNotification(t('BottomNotifications.Success')));
          })
        }}>
        {t('GlobalTools.Modify Kid Birthdays')}
      </Button>
      <br />
      <br />
      <hr />
      <br />
      {isAwoWW() && (<><Button
        onClick={() => {
          removeNotificationGroupsAWO().then((res) => {
            store.dispatch(showBottomNotification(t('BottomNotifications.Success')));
          });
        } }>
        {t('GlobalTools.RemoveGroupsFromNotificationListForAWO')}
      </Button><br /><br /><hr /><br /></>)}
      {isAwoWW() && (<><Button
        onClick={() => {
          addFavoritesToGroupsAWO().then((res) => {
            store.dispatch(showBottomNotification(t('BottomNotifications.Success')));
          });
        } }>
        {t('GlobalTools.AddLastAccessGroupsToNotificationListForAWO')}
      </Button><br /><br /><hr /></>)}
      <div>
        <br />
        <Button onClick={() => {
          deleteExpiredOAuthTokens()
            .then(data => {
              store.dispatch(showBottomNotification(t('BottomNotifications.Success')));
            })
            .catch(data => {
              store.dispatch(
                showBottomNotification(t('BottomNotifications.Something went wrong'), {
                  isFail: true,
                }),
              );
            })
            .finally(() => {
              setIsLoading(false);
            });
        }}>
          {t('GlobalTools.Delete expired oauth tokens')}
        </Button>
      </div>
      <br />
      <hr />
      <div>
        <br />
        <Button onClick={() => {
          deleteDuplicateAuthorizedToPickUpChild()
            .then(data => {
              store.dispatch(showBottomNotification(t('BottomNotifications.Success')));
            })
            .catch(data => {
              store.dispatch(
                showBottomNotification(t('BottomNotifications.Something went wrong'), {
                  isFail: true,
                }),
              );
            })
            .finally(() => {
              setIsLoading(false);
            });
        }}>
          {t('GlobalTools.Delete duplicate authorized to pick up child')}
        </Button>
      </div>
      <hr />
      <div>
        <br />
        <Button onClick={() => {
          setIsLoading(true);
          addAllAwowwUsersToAllWelcomeGroups()
            .then(data => {
              store.dispatch(showBottomNotification(t('BottomNotifications.Success')));
            })
            .catch(data => {
              store.dispatch(
                showBottomNotification(t('BottomNotifications.Something went wrong'), {
                  isFail: true,
                }),
              );
            })
            .finally(() => {
              setIsLoading(false);
            });
        }}>
          {t('GlobalTools.Invite all AWOWW Users to all DefaultGroups')}
        </Button>
      </div>
    </div>
  ) : (
    <Loader />
  );
}
